:root {
  --primary: #635afe;
  --secondary: #f2f7ff;
  --primary-invert: #31303d;
  --secondary-invert: #fff;

  --gradient-primary: #635afe;
  --gradient-secondary: linear-gradient(230deg, #0a1f44, #020024);

  /* DEFAULT COLOR OF TEXTS */
  --text-primary: #31303d;
  --text-secondary: #88909b;

  /* FOR BUTTONS & INPUTS */
  --elements-roundness: 10rem;

  --space-between-blocks: 5.3rem;
  /* for mobiles */
  --space-between-blocks-small-screens: 3rem;
}

@font-face {
  font-family: Circuler;
  src: url(/src/fonts/CircularStdBook.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Circuler;
  src: url(/src/fonts/CircularStdMedium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Circuler;
  src: url(/src/fonts/CircularStdBold.ttf);
  font-weight: 900;
}

body {
  font-family: Circuler;
  color: var(--text-primary);
  overflow: hidden !important;
  background: #1a1c1e;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 1600px;
  }
}

.space-between-blocks {
  padding-top: var(--space-between-blocks-small-screens);
  padding-bottom: var(--space-between-blocks-small-screens);
}

@media (min-width: 992px) {
  .space-between-blocks {
    padding-top: var(--space-between-blocks);
    padding-bottom: var(--space-between-blocks);
  }
}

.tox-statusbar__branding {
  display: none !important;
}

.svgMargin {
  margin-right: 12px;
}

button {
  color: inherit;
  padding: 0;
  background: none;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.btn {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 2rem;
  border: none;
  /* border-radius: var(--elements-roundness); */
  justify-content: center;
}

.btn,
.btn:hover,
.btn:focus {
  border: none;
  box-shadow: none;
  outline: none;
  color: inherit;
}

.btn-listview{
  background: #363a40;
  color: #6359fd;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background: none;
  color: var(--secondary-invert);
  border: 1px solid var(--secondary-invert);
  border-radius: 3px;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  background: var(--primary);
  color: white;
  border-radius: 5px;
}

.btn-tertiary,
.btn-tertiary:hover,
.btn-tertiary:focus {
  width: 246px;
  background: var(--primary);
  color: white;
  border-radius: 5px;
}

.btn-primary--empty,
.btn-primary--empty:hover,
.btn-primary--empty:focus {
  color: white;
  border: 1px solid #363a40;
}

.btn-sm {
  padding: 0.8rem;
}

.highlight {
  color: var(--primary);
}

.badge.bg-primary {
  background: var(--primary) !important;
}

/* block header */

.block__header {
  margin-bottom: 4rem;
}

.block__title {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .block__title {
    font-size: 2.5rem;
  }

  .block__title--big {
    font-size: 3rem;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .block__title--big {
    font-size: 3.5rem;
    line-height: 1.2;
  }
}

.block__paragraph {
  font-size: 1.1rem;
  line-height: 1.5;
  opacity: 0.9;
}

@media (min-width: 992px) {
  .block__paragraph--big {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

/* Icons */

.fr-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.fr-icon--medium {
  font-size: 1.5rem;
  height: 3rem;
  width: 3rem;
}

.fr-icon--large {
  font-size: 1.2rem;
  height: 3.25rem;
  width: 3.25rem;
}

@media (min-width: 992px) {
  .fr-icon--large {
    height: 3.8rem;
    width: 3.8rem;
    font-size: 1.9rem;
  }
}

.form-select {
  /* Why the !important?? you make it harder for anyone else after you!!! */
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 5'><path fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") right .75rem center/8px 10px no-repeat!important;
  padding: 0.9rem 1.4rem !important;
  /*background: #282a2e;*/
  border: 1px solid #363a40;
  color: white;
}

/* 1. Hero */
.hero {
  --hero-nav-height: 60px;
  --block-background: var(--gradient-primary);
  --block-text-color: var(--text-primary);
  background: #0d0e10; /*var(--block-background);*/
  color: var(--block-text-color);
  position: relative;
  display: flex;
  flex-direction: column;
}

/* 2. Hero Navigation */
.hero-nav {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--hero-nav-height);
  display: flex;
  align-items: center;
  z-index: 2;
}

/* 1. Navigation, links, and mobile menu */
.hero-nav__item {
  margin: auto;
  font-size: 1.1rem;
}

@media (min-width: 992px) {
  .hero-nav__item {
    margin: 0 1rem;
  }
}

@media (min-width: 992px) {
  .nav--lg-side {
    flex-direction: row-reverse;
  }
}

.hero-nav__logo {
  height: 20px;
}

.hero-nav__link {
  color: inherit;
  opacity: 0.9;
  text-decoration: none;
}

.hero-nav__link:focus,
.hero-nav__link:hover {
  color: inherit;
  text-decoration: none;
  opacity: 1;
}

.ft-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (print),
(prefers-reduced-motion: reduce) {
  .ft-menu {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

.ft-menu .hero-nav__item {
  width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 992px) {
  .ft-menu .hero-nav__item {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ft-menu--js-show {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.ft-menu::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--primary-invert);
  z-index: -1;
}

@media (min-width: 992px) {
  .ft-menu {
    -webkit-animation-name: none;
    animation-name: none;
    position: static;
    z-index: auto;
  }

  .ft-menu::before {
    content: none;
  }
}

.ft-menu__slider {
  --block-background: black;
  --block-text-color: var(--secondary-invert);
  color: var(--block-text-color);
  background: var(--block-background);
  width: 80%;
  height: 100%;
  overflow: hidden;
  transform: translateX(-100%);
  transition: 0.5s transform;
}

@media (min-width: 992px) {
  .ft-menu__slider {
    --block-background: transparent;
    --block-text-color: var(--block-text-color);
    width: auto;
    height: auto;
    background: 0 0;
    transform: none;
    display: flex;
    align-items: center;
  }
}

.ft-menu--js-show .ft-menu__slider {
  transform: translateX(0);
}

.ft-menu__close-btn {
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2rem;
  margin: 1rem;
  transform: translateX(100%);
  transition: 0.5s transform;
}

@media (min-width: 992px) {
  .ft-menu__close-btn {
    display: none;
  }
}

.ft-menu--js-show .ft-menu__close-btn {
  transform: translateX(0);
}

.ft-menu__close-btn:focus,
.ft-menu__close-btn:hover {
  color: var(--primary-invert);
}

@-webkit-keyframes fadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

/* 2.2. Sticky Navigation */
.fixed-nav-container {
  height: var(--hero-nav-height);
}

.hero-nav--is-sticky {
  --block-background: white;
  color: var(--block-text-color);
  background: var(--block-background);
  position: fixed !important;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

  padding: 1rem !important;
  height: 60px;

  transition: 0.2s height;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@media (min-width: 992px) {
  .hero-nav--is-sticky {
    height: 80px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1rem 0.7rem !important;
  }
}

.ft-menu__slider {
  color: var(--block-text-color);
}

/* Change color for the navigation once it's sticky */
.hero-nav--is-sticky .ft-menu__slider {
  color: var(--text-primary);
}

/* Change color for the navigation button once it's sticky */
.hero-nav--is-sticky .btn {
  background: var(--gradient-primary);
  color: var(--primary-invert);
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* New CSS for dropdown */
.ft-menu__slider .hero-nav__item {
  font-size: 1.1rem;
  padding: 0 1rem;
}

@media (min-width: 992px) {
  .ft-menu__slider .hero-nav__item {
    padding: 0;
  }
}

.hero-nav__link {
  display: flex;
  align-items: center;
  outline: none !important;
}

.ft-menu__slider {
  overflow-x: hidden;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .ft-menu__slider {
    overflow: visible;
  }
}

/* 2.3. Dropdowns */
.hero-nav__item--with-dropdown {
  position: relative;
  cursor: pointer;
}

/*
@media (min-width: 992px) {
  .hero-nav__item--with-dropdown::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 176%;
  }
}
*/

.hero-nav__dropdown {
  position: static;
  width: 100%;
  height: auto;
  font-size: 0.9rem;
  border-top: 1px solid rgb(255 255 255 / 30%);
  border-bottom: 1px solid rgb(255 255 255 / 30%);
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all;
  color: white;
  text-align: initial;
  cursor: auto;
}

@media (min-width: 992px) {
  .hero-nav__dropdown {
    position: absolute;
    top: 176%;
    left: -42px;
    margin-top: 0;
    border-radius: 5px;
    border-top: none;
    box-shadow: 0 14px 50px 0 rgba(0, 0, 0, 0.1);
    background-color: #282a2e;
    z-index: 2;
    max-height: 0;
    padding: 2.3rem 3rem 2rem 2rem;
    overflow: visible;
    transition: 0.3s all;
    border: 1px solid #363a40;
  }
}

.dropdown--important {
  position: absolute;
  width: auto !important;
  top: 176%;
  left: 0;
  margin-top: 0;
  border-radius: 10px;
  border-top: none;
  box-shadow: 0 14px 50px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 2;
  max-height: 0;
  padding: 2rem;
  padding-right: 3rem;
  overflow: visible;
  transition: 0.3s all;
}

@media (min-width: 992px) {
  .dropdown_workspace {
    width: 315px !important;
  }

  .dropdown--of-1-columns {
    width: 270px;
  }

  .dropdown--of-2-columns {
    width: 700px;
  }

  .dropdown--language-selector {
    width: 250px;
  }
}

.hero-nav__item--show-dropdown .hero-nav__dropdown {
  margin-top: 1rem;
  visibility: visible;
  opacity: 1;
  max-height: 9999px;
  padding: 1.3rem 0rem;
  transform: inherit;
}

@media (min-width: 992px) {
  .hero-nav__item--show-dropdown .hero-nav__dropdown {
    margin-top: 0;
    padding: 2rem;
    transform: translateX(calc(-50% + 44.3672px));
  }
}

.hero-nav__item-chevron {
  transition: 0.3s all;
}

.hero-nav__item--show-dropdown .hero-nav__item-chevron {
  transform: rotate(180deg);
}

.hero-nav__item--dropdown-left .hero-nav__dropdown {
  transform: translateX(-15px) !important;
}

.hero-nav__item--dropdown-left .hero-nav__dropdown::before {
  left: 15%;
  right: auto;
}

.hero-nav__item--dropdown-right .hero-nav__dropdown {
  transform: translateX(15px) !important;
  left: auto;
  right: 0;
}

.hero-nav__item--dropdown-right .hero-nav__dropdown::before {
  left: auto;
  right: 15%;
}

@media (min-width: 992px) {
  .hero-nav__item--lg-dropdown-left .hero-nav__dropdown {
    transform: translateX(-15px) !important;
    left: 0;
    right: auto;
  }

  .hero-nav__item--lg-dropdown-left .hero-nav__dropdown::before {
    left: 15%;
    right: auto;
  }

  .hero-nav__item--lg-dropdown-right .hero-nav__dropdown {
    transform: translateX(15px) !important;
    left: auto;
    right: 0;
  }

  .hero-nav__item--lg-dropdown-right .hero-nav__dropdown::before {
    left: auto;
    right: 15%;
  }
}

/*
  NOTE:
  Dropdown Important: will force the same design and behavior in
  all devices (mobile, tablets, etc)
*/
.dropdown--important::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6.85px 8.1px 6.85px;
  border-color: transparent transparent white transparent;
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  margin: auto;
}

/*
@media (min-width: 992px) {
  .hero-nav__dropdown::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6.85px 8.1px 6.85px;
    border-color: transparent transparent white transparent;
    position: absolute;
    top: -6px;
    right: 55px;
    margin: auto;
  }
}
*/

.dropdown__title {
  color: var(--text-primary);
  opacity: 0.7;
  font-weight: 600;
  font-size: 0.9rem;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1.18rem;
}

.dropdown__link_last {
  color: #fb526a !important;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.dropdown__link {
  margin-bottom: 1.5rem;
  color: white !important;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.dropdown__link:hover,
.dropdown__link:focus {
  color: white !important;
  text-decoration: none;
}

@media (min-width: 992px) {
  .dropdown__workspaces-list{
    overflow: auto;
    height: 354px;
  }
}

/*
@media (min-width: 992px) {
  .dropdown__link {
    color: var(--text-secondary) !important;
  }

  .dropdown__link:hover,
  .dropdown__link:focus {
    color: var(--text-primary) !important;
  }
}
*/


[javascript-language-selector] .dropdown__link {
  border-radius: 0.5rem;
  padding: 0.7rem;
  margin-bottom: 3px;
}

.dropdown__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
.dropdown__link:last-child {
  margin: 0;
}
*/

[javascript-language-selector] .dropdown__link--selected,
[javascript-language-selector] .dropdown__link:hover,
[javascript-language-selector] .dropdown__link:focus {
  background: rgba(0, 0, 0, 0.06);
}

.dropdown__icon {
  width: 20px;
  height: 20px;
  color: white;
}

@media (min-width: 992px) {
  .dropdown__icon {
    color: var(--text-secondary);
  }
}

.dropdown__item {
  max-width: 250px;
}

.dropdown__item-title {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
  z-index: -1;
}

@media (min-width: 992px) {
  .dropdown__item-title {
    white-space: nowrap;
  }
}

.dropdown__item-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
  background: var(--secondary);
  transform: translateY(2px);
  opacity: 0;
  transition: 0.2s;
}

[javascript-language-selector] .dropdown__item-title {
  white-space: nowrap;
}

[javascript-language-selector] .dropdown__item-title::before {
  display: none;
}

.dropdown__item-description {
  font-size: 0.9rem;
  opacity: 0.9;
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.dropdown__item-title,
.dropdown__item-description {
  width: 95%;
}

/* 3. Hero Row (image container) */
.hero__row {
  height: 100%;
}

.hero_empty-column {
  height: 100%;
}

.hero__image-column {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.hero__content {
  text-align: initial;
}

.hero__body {
  padding-top: 2rem;
  padding-bottom: 3rem;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .hero__body {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}

.hero__title {
  font-size: 1.8rem;
  font-weight: 900;
}

.hero__paragraph {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  opacity: 0.98;
}

@media (min-width: 576px) {
  .hero__title {
    font-size: 2.5rem;
    line-height: 1.17;
  }

  .hero__paragraph {
    width: 80%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .hero__title {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  .hero__title {
    font-size: 2.5rem;
    line-height: 1.17;
  }

  .hero__paragraph {
    font-size: 1.2rem;
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .hero__title {
    font-size: 3.5rem;
    line-height: 1.17;
  }
}

.hero__btns-container {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.hero__btns-container .btn {
  transform: scale(1.15);
  border-radius: 10px;
}

.hero__btns-container {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.hero__btns-container .btn {
  transform: scale(1.15);
  border-radius: 10px;
}

/****  FastEmail Sliders  ****/

.FastEmail-templates__count {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 1.5rem 1rem 0;
  font-size: 18px;
  color: white;
}

.FastEmail__videoJS {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-js {
  width: 102%;
  height: 200px;
}

.FastEmail__tip-title {
  font-size: 2.5rem;
  text-align: center;
}

.FastEmail__tip-description {
  font-size: 1.2rem;
}

.FastEmail__tags {
  height: 100vh;
  background: #1a1c1e;
  overflow: hidden;
  display: none;
}

.FastEmail-templates__listview .btn-group {
  display: none;
}

.FastEmail-templates__listview {
  display: flex;
}

.FastEmail-templates__listview button {
  border: 1px solid #363a40;
  height: 30px;
}

.FastEmail-templates__listview select {
  height: 30px;
  margin-left: 10px;
  padding: 2px 30px 2px 10px !important;
}

@media (min-width: 768px) {
  .video-js {
    width: 710px;
    height: 500px;
  }
}

@media (min-width: 992px) {
  .FastEmail__tags {
    display: block;
    border-right: 1px solid #363a40;
  }

  .FastEmail-templates__listview .btn-group {
    display: flex;
  }

}

.FastEmail__tags-list {
  display: flex;
  flex-direction: column;
  margin: 0rem 1rem;
}

.FastEmail__tags-link {
  color: #686a6c;
  text-decoration: none;
  list-style-type: none;
  padding-bottom: 1rem;
}

.FastEmail__tags-link:focus,
.FastEmail__tags-link:hover{
  color: white;
}

.FastEmail__templates {
  height: 100vh;
  background-color: #16181ae6;
  overflow: hidden;
}

.FastEmail__scroll {
  height: calc(100% - 120px);
  overflow: scroll;
}

.FastEmail-card {
  padding: 1.5rem 1.5rem 1rem;
  border-radius: 10px;
  position: relative;
  background: #282a2e;
  /*box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);*/
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #363a40;
}

.FastEmail-card__paragraph {
  color: #989da8;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.7;
  flex-grow: 1;
  margin: 1rem 0rem 1.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.FastEmail-card__row-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

.FastEmail-card__name {
  font-size: 19px;
  font-weight: 600;
  color: white;
}

.FastEmail-card__btn {
  height: 30px;
  width: 30px;
  display: block;
  align-items: center;
  justify-content: center;
  align-content: center;
  /*color: #db4848;*/
  display: block;
}

.FastEmail-card:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.09);
}

/*
.FastEmail-card:hover .FastEmail-card__btn-delete {
  display: block;
}
*/

@media (min-width: 992px) {
  /*
  .FastEmail-card__btn-delete {
    display: none;
  }
  */

  .FastEmail-card {
    padding: 2rem 2rem 1rem;
  }
}

.FastEmail-card__row-bottom {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.FastEmail-card__permissions {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0.1rem 0.5rem;
  height: 22px;
  border-radius: 3px;
  border: 1px solid #1661c0;
  background: #cff5f6;
  color: #1661c0;
  cursor: default;
}

.FastEmail-card__shortcut {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 0.2rem 1rem;
  border-radius: 5rem;
  /*border: 1px solid #03690c;*/
  background: #625afe;
  color: white;
  /*margin: 0rem 0.5rem 1rem 0rem;*/
  cursor: default;
  box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
}

.FastEmail-card__tag {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: .2rem 1rem;
  border-radius: 5px;
  background: #35373b;
  color: #79797a;
  margin: 0rem 0.5rem 1rem 0rem;
  cursor: default;
}

.ui-container {
  /*background: white;*/
  padding: 0 1rem;
  /*border-bottom: 1px solid #e5e7eb;*/
}

.ui-search {
    /* color: #88909b; */
    color: #686a6c;
    display: flex;
    padding: 1rem;
    align-content: center;
    align-items: center;
    /* backdrop-filter: blur(50px);
    background: #282a2e;
    box-shadow: 0 10px 15px rgb(0 0 0 / 30%); */
    margin-top: 1rem;
    border-radius: 10px;
    border: 2px solid #363a40;

    background: #282a2e7a;
    backdrop-filter: blur(40px);
}

.ui-search-tags_btn {
  display: block;
}

.close-ui-search-tags {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--text-primary);
}

@media (min-width: 992px) {
  .ui-search-tags_btn {
    display: none;
  }

  .close-ui-search-tags {
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    background: var(--text-primary);
    display: none;
  }
}

.ui-search__icon {
  margin-right: 1rem;
}

.ui-search__input {
    font: inherit;
    color: white;
    border: none;
    outline: none;
    width: 100%;
    background: none;
}

::placeholder {
  font-weight: 400;
  color: var(--text-secondary);
  opacity: 1;
}

.Close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-secondary);
}

/*----- New Template Popup ------*/
.FastEmail__popup-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: none; /*#16181a;*/
  z-index: 3;
  overflow: auto;
  background: #16181ae6;
}

.FastEmail__popup-background-center {
  /* align-items: center !important; */
}

.FastEmail__team-popup-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f8fafc;
  z-index: 3;
  overflow: auto;
}

@media (min-width: 992px) {
  /*
  .FastEmail__popup-background {
    padding: 3rem;
  }
  */

  .FastEmail__team-popup-background {
    padding: 10rem 2rem 0;
  }
}

.FastEmail__popup {
  color: white;
  border-radius: 8px;
  padding: 2.5rem 1.4rem;
  /* margin: 0 -0.5rem;
  background: #1a1c1e; */
  width: auto;
  height: auto;
  /*box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);*/
}

@media (min-width: 768px) {
  .FastEmail__popup {
    padding: 2.5rem 2rem;
  }
}

.FastEmail__popup__header {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.FastEmail__popup__title {
  text-align: left;
  color: var(--primary);
  font-size: 2.3rem;
  font-weight: 600;
  cursor: default;

  background-image: -webkit-linear-gradient(0deg, #feac5e, #E35E88, #6359fd);
  -webkit-background-clip: text;
  color: transparent;
}

.FastEmail__popup__list {
  width: 35%;
  background: none;
  padding: 0.9rem 1.4rem;
  font-size: 0.87rem;
}

.FastEmail__popup__input {
  color: white; /*#686a6c;*/
  /*background: #282a2e;*/
  width: 100%;
  padding: 0.9rem 1.4rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #363a40;
  background: #282a2e7a;
  backdrop-filter: blur(40px);
}

.FastEmail__popup__input:last-child {
  margin-bottom: 0;
}

.FastEmail__popup__input:focus {
  border-color: var(--primary);
  background: rgba(0, 0, 0, 0.05);
  outline: 0;
  box-shadow: none;
}

.FastEmail__popup__p {
  font-size: 1rem;
  line-height: 1.4;
  text-align: left;
  cursor: default;
}

.FastEmail__popup__p-description {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.4;
  text-align: left;
  cursor: default;
}

.btn-form,
.btn-form:focus,
.btn-form:hover {
  background: var(--primary);
  color: var(--primary-invert);
  border-radius: 5px;
  font-size: 0.87rem;
  padding: 0.8rem 1.6rem;
  transition: 0.2s all;
}

.reset__link,
.reset__link:focus,
.reset__link:hover {
  text-decoration: none;
  color: var(--primary);
}

.warningMain {
  text-align: center;
  font-weight: "600";
  flex: 10;
}

.warningDiv {
  width: 100%;
  color: #db4848;
  background-color: #ffebeb;
  display: flex;
  flex-direction: row;
  height: 60px;
  margin: 0;
}

@media (max-width: 820px) {
  .warningDiv {
    height: 100px;
  }
}

.editButton {
  background-color: red;
  z-index: 999;
  width: 100%;
  transform: translateY(-20px) !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #1a1c1e;
}

::-webkit-scrollbar-thumb {
  background: #79797a;
  border-radius: 5rem;
}

/* Unwanted Emojis */
[title="man in steamy room"] {
  display: none !important;
}

[title="couple with heart man man"] {
  display: none !important;
}

[title="couple with heart woman man"] {
  display: none !important;
}

[title="couple with heart woman woman"] {
  display: none !important;
}

[title="couplekiss man woman"] {
  display: none !important;
}

[title="couplekiss man man"] {
  display: none !important;
}

[title="couplekiss woman woman"] {
  display: none !important;
}

.tox .tox-dialog{
  border-color: #e4e5e7 !important;
  border-radius: 8px !important;
}

.tox .tox-dialog-wrap__backdrop{
  background-color: rgba(34,47,62,.75) !important;
}

