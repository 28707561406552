/* Login & Signup */
.FastEmail_Background{
  height: 100vh;
  background-color: rgba(22,24,26,.9);
}

.FastEmail__Login {
  display: flex;
}

.FastEmail__Signup {
  display: flex;
}

.FastEmail__ForgetPassword {
  display: none;
}

.FastEmail__logo-container {
  text-align: center;
  margin: 2rem 0 1rem 0;
}

.FastEmail__logo {
  height: 1.5rem;
}


.FastEmail__plus__logo {
  display: block;
  font-weight: 900;
  font-size: 30px;
  padding: 5px;
}

.fullheight {
  height: 100%;
  min-height: 100vmin;
  display: flex;
}

.flex_centralize {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 992px) {
  .dropdown__item-title {
    white-space: nowrap;
    margin-bottom: 0;
  }

  .FastEmail__plus__logo {
    vertical-align: middle;
    display: inline;
  }
}

.FastEmail-auth-form {
  border-radius: 5px;
  padding: 1.5rem;
  margin: 0 -0.5rem;
  /*background: white;
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.05);*/
}

@media (min-width: 500px) {
  .FastEmail-auth-form {
    padding: 2.5rem 1.4rem;
  }
}

@media (min-width: 768px) {
  .FastEmail-auth-form {
    padding: 2.5rem;
  }
}

.FastEmail-auth-form__title {
  text-align: center;
  font-size: 1.13rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: white;
}

.FastEmail-auth-form__input {
  width: 100%;
  padding: 0.9rem 1.4rem;
  font-size: 0.87rem;
  margin-bottom: 0.87rem;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #363a40;
  background: rgba(40,42,46,.47843137254901963);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.FastEmail-auth-form__input:last-child {
  margin-bottom: 0;
}

.FastEmail-auth-form__input:focus {
  border-color: var(--primary);
  background: rgba(0, 0, 0, 0.05);
  outline: none;
  box-shadow: none;
}

.FastEmail-auth-form__options button {
  width: 100%;
}

.border-bottom {
  border: 0;
  border-bottom: 1px solid #dee2e6;
}

.FastEmail-auth-form__p {
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: center;
  color: white;
}

.btn-form,
.btn-form:hover,
.btn-form:focus {
  background: var(--primary);
  color: white;
  border-radius: 5px;
  font-size: 0.87rem;
  padding: 0.8rem 1.6rem;
  transition: 0.2s all;
}

.FastEmail__link,
.FastEmail__link:focus,
.FastEmail__link:hover {
  text-decoration: none;
  color: var(--primary);
}